<template>
  <div ref="playerContainer" className="playerContainer" class="playerContainer"/>
</template>

<script>
import ArtPlayer from 'artplayer/dist/artplayer.legacy.js';
import artplayerProxyCanvas from 'artplayer-proxy-canvas/dist/artplayer-proxy-canvas.legacy';
import flvjs from 'flv.js'
import Hls from 'hls.js'

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    muted: {
      type: Boolean,
      default: false
    },
    canvas: {
      type: Boolean,
      default: false
    },
    timeListenMethod: Function
  },
  data() {
    return {
      player: null,
    }
  },
  mounted() {
    this.initArtPlayer(this.url)
  },
  methods: {
    // 当容器只有宽度，该属性可以自动计算出并设置视频的高度
    autoHeight() {
      this.player.on('ready', () => {
        this.player.autoHeight();
      });

      this.player.on('resize', () => {
        this.player.autoHeight();
      });
    },

    /**
     * 更换播放地址
     * @param url 播放地址
     * @param historyPlayTime 历史播放时间
     */
    changeUrl(url, historyPlayTime) {
      // 远程如有历史播放时间将设置播放记录时间
      if (url && historyPlayTime) {
        let time = Number(historyPlayTime)
        if (time > 5) {
          time -= 5
        }
        let times = this.player.storage.get('times');
        if (!times) {
          times = {}
        }
        times[url] = time
        this.player.storage.set('times', times);
      }


      this.player.destroy(true);
      this.player = null
      this.initArtPlayer(url)
      // this.player.play();
    },
    /**
     * 销毁当前播放器
     */
    destroy() {
      this.player.destroy(true);
      this.player = null
    },

    playM3u8(video, url, art) {
      if (Hls.isSupported()) {
        if (art.hls) art.hls.destroy();
        const hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(video);
        art.hls = hls;
        art.on('destroy', () => hls.destroy());
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = url;
      } else {
        art.notice.show = 'Unsupported playback format: m3u8';
      }
    },
    playFlv(video, url, art) {
      if (flvjs.isSupported()) {
        if (art.flv) art.flv.destroy();
        const flv = flvjs.createPlayer({type: 'flv', url});
        flv.attachMediaElement(video);
        flv.load();
        art.flv = flv;
        art.on('destroy', () => flv.destroy());
      } else {
        art.notice.show = 'Unsupported playback format: flv';
      }
    },
    /**
     * 初始化视频组件
     * @param videoAddress
     */
    initArtPlayer(videoAddress) {
      ArtPlayer.USE_RAF = true;
      ArtPlayer.DEBUG = false
      // 历史记录播放-记录数量
      ArtPlayer.AUTO_PLAYBACK_MAX = 20;
      // 发生连接错误时，自动连接的最大次数
      ArtPlayer.RECONNECT_TIME_MAX = 5;
      ArtPlayer.LOG_VERSION = false;
      // 在移动端,长按加速的速率倍数
      ArtPlayer.FAST_FORWARD_VALUE = 3;
      // 内置播放速率的列表
      ArtPlayer.PLAYBACK_RATE = [0.5, 1, 1.5, 2, 3, 4, 5];
      // 跳转播放框显示时长
      ArtPlayer.AUTO_PLAYBACK_TIMEOUT = 15000
      // 内置视频长宽比的列表
      ArtPlayer.ASPECT_RATIO = ['default', '1:1', '2:1', '4:3', '6:5'];
      let config = {
        id: videoAddress,
        // container: this.$refs.playerContainer,
        container: '.playerContainer',
        poster: require('@/assets/image/15534421978485056_compressed.jpg'),
        url: videoAddress,
        // 是否自动播放
        autoplay: false,
        // 隔空播放
        airplay: true,
        // 是否默认静音
        muted: this.muted,
        volume: 0.1,
        // 是否使用自动 回放功能 历史记录
        autoPlayback: true,
        autoMini: true,
        playbackRate: true,
        aspectRatio: true,
        screenshot: true,
        hotkey: true,
        pip: true,
        fullscreen: true,
        // 迷你进度条
        miniProgressBar: true,
        // 是否在移动端添加长按视频快进功能
        fastForward: true,
        setting: true,
        // 通过视频的 type 进行匹配，把视频解码权交给第三方程序进行处理，处理的函数能接收三个参数
        customType: {
          m3u8: this.playM3u8,
          flv: this.playFlv,
        },
        // 设置缓冲区大小，单位为秒
        buffer: 20,
        // 视频属性，这些属性将直接写入视频元素里
        moreVideoAttr: {
          'webkit-playsinline': true,
          playsInline: true,
          preload: 'auto',
        },
        icons: {
          loading: '<img src=' + require('@/assets/image/bdbeafa79ebd45fbbe15b4e3b011456b.gif') + '>',
        },
      };
      // 根据配置选择是否使用画布播放
      if (this.canvas) {
        config['proxy'] = artplayerProxyCanvas()
      } else {
        delete config['proxy']
      }
      if (!videoAddress) {
        delete config['url']
      }
      this.player = new ArtPlayer(config);


      //将监听播放时长返回给父组件
      if (this.timeListenMethod) {
        this.player.on('video:timeupdate', (o) => {
          this.timeListenMethod(o.srcElement.currentTime)
        });
      }

      this.player.on('ready', () => {
        this.player.play();
      });
    }
  },
  destroyed() {
    this.player.destroy();
    this.player = null;
  }
};
</script>

<style>
.playerContainer {
  aspect-ratio: 16/9;
}
</style>